import React, { useEffect } from 'react'
import {
  Alert,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectParams,
  selectSessionId,
} from '../../../redux/selectors/checkoutSelectors'
import { Address } from '../../../models/Address'
import { useCreateFulfillmentQuoteMutation } from '../../../redux/api/checkoutApi'
import FulfillmentQuoteMethodList from './FulfillmentQuoteMethodList'
import ListSkeleton from '../../../common/ListSkeleton'
import { getError } from '../../../helpers/checkout'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import { LoadingButton } from '@mui/lab'
import {
  AddressSource,
  FulfillmentQuoteMethod,
} from '../../../models/FulfillmentQuote'
import { useFormContext, useWatch } from 'react-hook-form-mui'
import useAddressFormValues from './useAddressFormValues'
import AnimateHeight from '../../../common/AnimateHeight'
import { Fulfillment } from '../../../models/Order'

interface FulfillmentQuoteContentProps {
  index: number
  label: string
  type: FulfillmentMethodType
  error?: string
  submitLoading: boolean
}

export default function FulfillmentQuoteContent({
  index,
  label,
  type,
  error,
  submitLoading,
}: FulfillmentQuoteContentProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const params = useAppSelector(selectParams)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))

  const [
    createFulfillmentQuote,
    {
      data: fulfillmentQuote,
      isLoading: fulfillmentQuoteLoading,
      error: fulfillmentQuoteError,
    },
  ] = useCreateFulfillmentQuoteMutation()

  const { setValue } = useFormContext<Fulfillment>()

  const method: FulfillmentQuoteMethod = useWatch({
    name: `method`,
  })

  const [savedAddressId, address, addressSource] = useAddressFormValues<
    [string, Address, AddressSource]
  >({
    names: ['savedAddressId', 'address', 'addressSource'],
  })

  useEffect(() => {
    const createQuote = async () => {
      if (
        sessionId &&
        params &&
        (type === FulfillmentMethodType.PICKUP || addressSource)
      ) {
        try {
          const quote = await createFulfillmentQuote({
            sessionId,
            userId: params?.userId,
            type,
            addressSource,
            address:
              addressSource === AddressSource.USER_SUPPLIED
                ? address
                : undefined,
            savedAddressId,
          }).unwrap()
          setValue(`quoteId`, quote._id)
          if (quote.methods.length === 1) {
            setValue(`method`, quote.methods[0])
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    createQuote()
  }, [
    sessionId,
    savedAddressId,
    address,
    addressSource,
    params,
    type,
    createFulfillmentQuote,
    setValue,
  ])

  const handleSelectMethod = (method: FulfillmentQuoteMethod) => {
    setValue(`method`, method)
  }

  return (
    <>
      <Typography variant="h6" p={2}>
        Select {label} Method
      </Typography>
      <AnimateHeight>
        {fulfillmentQuote && !fulfillmentQuoteLoading && (
          <FulfillmentQuoteMethodList
            methods={fulfillmentQuote.methods}
            selectedMethodId={method?.methodId}
            onSelectMethod={handleSelectMethod}
          />
        )}
        {!fulfillmentQuote && fulfillmentQuoteLoading && (
          <ListSkeleton primaryText secondaryAction quantity={3} />
        )}
      </AnimateHeight>
      {fulfillmentQuoteError && (
        <CardContent>
          <Alert severity="error">
            Unable to load methods: {getError(fulfillmentQuoteError)}
          </Alert>
        </CardContent>
      )}
      <CardContent>
        <Stack direction={'column'} spacing={2}>
          {error && <Alert severity="error">{error}</Alert>}
          <Stack direction={'row'} justifyContent={'center'}>
            <LoadingButton
              data-testid="fulfillment-continue-btn"
              variant="contained"
              size="large"
              loading={submitLoading}
              fullWidth={xsDisplay}
              type="submit"
            >
              Continue
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </>
  )
}
