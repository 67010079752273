import React, { useEffect, useMemo } from 'react'
import { Button, Grid2, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CheckoutTextField from './CheckoutTextField'
import { LoadingButton } from '@mui/lab'
import { useFormContext } from 'react-hook-form-mui'

interface AddressContactFormProps {
  label: string,
  formPrefix?: string
  requirePhone?: boolean
  requireCompany?: boolean
  companyRename?: string
  restrictName?: boolean
  restrictEmail?: boolean
  restrictPhone?: boolean
  restrictCompany?: boolean
}

export default function AddressContactForm({
  label,
  formPrefix,
  requirePhone = false,
  requireCompany = false,
  companyRename,
  restrictName = false,
  restrictEmail = false,
  restrictPhone = false,
  restrictCompany = false
}: AddressContactFormProps) {
  const theme = useTheme()

  const { trigger } = useFormContext();

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  useEffect(() => {
    trigger([`${effectivePrefix}contact`])
  }, [effectivePrefix, trigger])

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="h6">{label} Contact</Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.firstName`}
            label="First Name"
            rules={{ required: true }}
            readOnly={restrictName}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.lastName`}
            label="Last Name"
            rules={{ required: true }}
            readOnly={restrictName}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.email`}
            label="Email"
            rules={{ required: true }}
            readOnly={restrictEmail}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.phone`}
            label="Phone"
            rules={{ required: requirePhone }}
            readOnly={restrictPhone}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.company`}
            label={companyRename ?? 'Company'}
            rules={{ required: requireCompany }}
            readOnly={restrictCompany}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <CheckoutTextField
            name={`${effectivePrefix}contact.attentionTo`}
            label="Attention To"
          />
        </Grid2>
      </Grid2>
    </Stack>
  )
}
