import React from 'react'
import { Stack } from '@mui/material'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectContactInfo,
  selectCostCenters,
  selectInHandsDate,
  selectOrderNote,
  selectParams,
  selectQuestions,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import PassiveRow from '../PassiveRow'
import moment from 'moment'

export default function CustomerDetailsPassiveContent() {
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)
  const inHandsDate = useAppSelector((state) =>
    selectInHandsDate(state, params),
  )
  const orderNote = useAppSelector((state) => selectOrderNote(state, params))
  const questions = useAppSelector((state) => selectQuestions(state, params))
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const { primaryCostCenter, secondaryCostCenter } = useAppSelector((state) => selectCostCenters(state, params))

  return (
    <Stack direction={'column'} gap={2}>
      {contactInfo && (
        <Stack>
          <PassiveRow
            field={'Name'}
            value={contactInfo.firstName + ' ' + contactInfo.lastName}
          />
          <PassiveRow field={'Email'} value={contactInfo.email} />
        </Stack>
      )}
      {settings.showInHandsDateField &&
        inHandsDate &&
        inHandsDate !== '0000-00-00' && (
          <PassiveRow
            field={
              settings.ihdRenameEnabled && settings.ihdRenameText
                ? settings.ihdRenameText
                : 'In Hands Date'
            }
            value={moment(inHandsDate).format('MM/DD/YYYY')}
          />
        )}
      {orderNote && (
        <PassiveRow
          field={'Order Note'}
          value={orderNote}
        />
      )}
      <Stack direction={'column'}>
        {questions?.map((q, idx) => (
          <PassiveRow
            key={idx}
            field={q.question}
            value={
              q.choiceValue +
              (q.choiceValue && q.value ? ` - ${q.value}` : q.value)
            }
          />
        ))}
      </Stack>
      {primaryCostCenter && (
        <PassiveRow
          field={settings.costCenterSettings?.primaryLabelText ?? 'Primary Cost Center'}
          value={`${primaryCostCenter?.costName} - ${primaryCostCenter?.code}`}
        />
      )}
      {secondaryCostCenter && (
        <PassiveRow
          field={settings.costCenterSettings?.secondaryLabelText ?? 'Secondary Cost Center'}
          value={`${secondaryCostCenter?.costName} - ${secondaryCostCenter?.code}`}
        />
      )}
    </Stack>
  )
}
