import {
  Alert,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import AddressForm from './AddressForm'
import React, { useMemo, useState } from 'react'
import ListSkeleton from '../../common/ListSkeleton'
import SavedAddressList from './fulfillment/SavedAddressList'
import { AddressSource } from '../../models/FulfillmentQuote'
import AddressContactForm from './AddressContactForm'
import useAddressFormValues from './fulfillment/useAddressFormValues'
import { RestrictAddressMode } from '../../models/CheckoutSession'
import _ from 'lodash'
import useAddressSelection from './useAddressSelection'
import AddressInfo from './AddressInfo'
import AnimateHeight from '../../common/AnimateHeight'

// Editing means the user initiated a change to the address or contact
// Even if editing if false, the form may still be open if there are missing fields
interface AddressSelectorProps {
  label: string
  contactOnly?: boolean
  requirePhone?: boolean
  requireCompany?: boolean
  companyRename?: string
  restrictAddressMode?: RestrictAddressMode
  restrictAddressId?: string
  hideAddress?: boolean
  lockName?: boolean
  lockEmail?: boolean
  children?: React.ReactNode
}

export default function AddressSelector({
  label,
  contactOnly,
  requirePhone,
  requireCompany,
  companyRename,
  restrictAddressMode,
  restrictAddressId,
  hideAddress,
  lockName,
  lockEmail,
  children,
}: AddressSelectorProps) {
  const [openAddressForm, setOpenAddressForm] = useState(false)
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const {
    loadingAddresses,
    loadingRestrictedAddress,
    addresses: savedAddresses,
    error,
    isValid,
    hasSavedAddresses,
    onSelectSavedAddress,
    onSubmit,
  } = useAddressSelection({
    contactOnly,
    restrictAddressMode,
    restrictAddressId,
    lockName,
    lockEmail,
    requirePhone,
    requireCompany,
  })

  const [editing, setEditing] = useState(false)

  const [savedAddressId, addressSource] = useAddressFormValues<
    [string, AddressSource]
  >({ names: ['savedAddressId', 'addressSource'] })

  const savedAddress = useMemo(
    () => savedAddresses?.find((a) => a.id === savedAddressId),
    [savedAddressId, savedAddresses],
  )

  const showAddressForm = useMemo(
    () =>
      openAddressForm ||
      (hasSavedAddresses === false &&
        !contactOnly &&
        restrictAddressMode === RestrictAddressMode.NONE),
    [openAddressForm, hasSavedAddresses, contactOnly, restrictAddressMode],
  )

  const handleContinue = async () => {
    await onSubmit()
    setEditing(false)
  }

  const handleChangeAddress = () => {
    setEditing(true)
    if (
      addressSource === AddressSource.USER_SUPPLIED ||
      savedAddresses?.length === 0
    ) {
      setOpenAddressForm(true)
    }
  }

  const handleEditAddress = () => {
    setOpenAddressForm(true)
  }

  const handleUpdateContact = async () => {
    await onSubmit()
    setEditing(false)
  }

  const handleEditContact = () => {
    setEditing(true)
  }

  const handleUseSavedAddress = () => {
    setOpenAddressForm(false)
  }

  const formOpen = editing || !isValid

  if (loadingRestrictedAddress || loadingAddresses) {
    return (
      <Box
        m={2}
        height={300}
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
      >
        <CircularProgress />
      </Box>
    )
  }

  // Displayed when all forms are closed
  if (!formOpen) {
    return (
      <Stack divider={<Divider flexItem />}>
        <Stack direction={'column'} m={2} spacing={2}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="h6">{label} Information</Typography>
            {!contactOnly &&
            restrictAddressMode !==
              RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION ? (
              <Button
                variant="outlined"
                fullWidth={xsDisplay}
                onClick={handleChangeAddress}
                data-testid="change-address-btn"
              >
                Change
              </Button>
            ) : (
              <Button
                variant="outlined"
                fullWidth={xsDisplay}
                onClick={handleEditContact}
                data-testid="edit-contact-btn"
              >
                Edit
              </Button>
            )}
          </Stack>
          <AddressInfo hideAddress={hideAddress} />
        </Stack>
        {children}
      </Stack>
    )
  }

  if (error && restrictAddressMode !== RestrictAddressMode.NONE) {
    return (
      <CardContent sx={{ pt: 0 }}>
        <Alert severity="error">Error loading address: {error}</Alert>
      </CardContent>
    )
  }

  if (loadingRestrictedAddress) {
    return (
      <Stack m={2} direction={'row'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>
    )
  }

  if (showAddressForm) {
    return (
      <Stack spacing={2} m={2}>
        {error && (
          <Alert severity="error">Error loading saved addresses: {error}</Alert>
        )}
        <AddressForm
          label={label}
          requirePhone={requirePhone}
          requireCompany={requireCompany}
          restrictAddress={restrictAddressMode !== RestrictAddressMode.NONE}
          companyRename={companyRename}
          restrictPhone={
            restrictAddressMode !== RestrictAddressMode.NONE &&
            !!savedAddress?.phone
          }
          restrictCompany={
            restrictAddressMode !== RestrictAddressMode.NONE &&
            !!savedAddress?.company
          }
          restrictName={lockName}
          restrictEmail={lockEmail}
          triggerContact={addressSource !== AddressSource.USER_SUPPLIED}
        />
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          {hasSavedAddresses && (
            <Button
              variant="outlined"
              size="large"
              onClick={handleUseSavedAddress}
              data-testid="use-saved-address-btn"
            >
              Use Saved Address
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            onClick={handleContinue}
            fullWidth={xsDisplay}
            data-testid="new-address-continue-btn"
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    )
  }

  // Form is open
  return (
    <Box>
      {formOpen && (
        <>
          <Stack spacing={2} m={2}>
            <AddressContactForm
              label={label}
              requirePhone={requirePhone}
              requireCompany={requireCompany}
              companyRename={companyRename}
              restrictName={lockName}
              restrictEmail={lockEmail}
              restrictPhone={
                restrictAddressMode !== RestrictAddressMode.NONE &&
                !!savedAddress?.phone
              }
              restrictCompany={
                restrictAddressMode !== RestrictAddressMode.NONE &&
                !!savedAddress?.company
              }
            />
            <Stack
              direction={'row'}
              justifyContent={'center'}
              mt={2}
              spacing={2}
            >
              {(contactOnly ||
                restrictAddressMode ===
                  RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION) && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleUpdateContact}
                  fullWidth={xsDisplay}
                  data-testid="contact-continue-btn"
                >
                  Continue
                </Button>
              )}
            </Stack>
          </Stack>
          {(loadingAddresses || hasSavedAddresses) && (
            <Stack>
              <Divider />
              <Typography variant="h6" p={2}>
                Select Address
              </Typography>
              <AnimateHeight>
                {loadingAddresses ? (
                  <ListSkeleton primaryText secondaryText quantity={3} />
                ) : (
                  <SavedAddressList
                    addresses={savedAddresses ?? []}
                    searchEnabled={true}
                    selectedAddressId={savedAddressId}
                    onSelectAddress={onSelectSavedAddress}
                  />
                )}
              </AnimateHeight>
              <CardContent>
                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                  {restrictAddressMode === RestrictAddressMode.NONE && (
                    <Button
                      variant="outlined"
                      onClick={handleEditAddress}
                      fullWidth={xsDisplay}
                      size="large"
                      data-testid="manually-enter-address-btn"
                    >
                      Manually Enter Address
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={handleContinue}
                    fullWidth={xsDisplay}
                    size="large"
                    data-testid="saved-address-continue-btn"
                  >
                    Continue
                  </Button>
                </Stack>
              </CardContent>
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}
