import React from 'react'
import { Alert, CardContent, IconButton, Stack } from '@mui/material'
import { useAppSelector } from '../../../redux/hooks'
import { selectDebug } from '../../../redux/selectors/checkoutSelectors'
import { CardConnectAccount } from '../../../models/CardConnectAccount'
import { Controller } from 'react-hook-form-mui'
import OptionCard from '../OptionCard'
import amex from '../../../assets/img/cards/amex.png'
import visa from '../../../assets/img/cards/visa.png'
import mastercard from '../../../assets/img/cards/mastercard.png'
import discover from '../../../assets/img/cards/discover.png'
import { Add, Delete } from '@mui/icons-material'
import CardConnectTokenForm from './CardConnectTokenForm'
import DeleteCCAccountDialog from './DeleteCCAccountDialog'

export interface CardConnectAccountListProps {
  name: string
  testModeEnabled: boolean
  accounts: CardConnectAccount[]
}

const CARD_IMG_MAP = {
  AMEX: amex,
  VISA: visa,
  MC: mastercard,
  DISC: discover,
}

const CARD_LABEL_MAP = {
  AMEX: 'American Express',
  VISA: 'Visa',
  MC: 'MasterCard',
  DISC: 'Discover',
}

export default function CardConnectAccountList({
  name,
  testModeEnabled,
  accounts,
}: CardConnectAccountListProps) {
  const debug = useAppSelector(selectDebug)

  const [pendingDeleteAccount, setPendingDeleteAccount] =
    React.useState<CardConnectAccount | null>(null)

  const handleSelect =
    (account: CardConnectAccount | null, onChange: (...event: any[]) => void) =>
    () => {
      onChange(account?.acctid ?? null)
    }

  const handleDeleteAccount = (account: CardConnectAccount) => () => {
    setPendingDeleteAccount(account)
  }

  const handleDeleteDialogClose = () => {
    setPendingDeleteAccount(null)
  }

  return (
    <>
      <DeleteCCAccountDialog
        account={pendingDeleteAccount}
        onClose={handleDeleteDialogClose}
      />
      <Controller
        name={name}
        render={({ field }) => (
          <Stack gap={2}>
            {accounts.map((account, idx) => (
              <OptionCard
                key={idx}
                label={
                  (CARD_LABEL_MAP[
                    account.accttype as keyof typeof CARD_LABEL_MAP
                  ] ?? account.accttype) +
                  ' - ' +
                  account.token.substring(
                    account.token.length - 4,
                    account.token.length,
                  )
                }
                onClick={handleSelect(account, field.onChange)}
                selected={field.value === account.acctid}
                iconComponent={
                  <img
                    src={
                      CARD_IMG_MAP[
                        account.accttype as keyof typeof CARD_IMG_MAP
                      ]
                    }
                    alt={account.accttype}
                    height={30}
                  />
                }
                actions={[
                  <IconButton onClick={handleDeleteAccount(account)}>
                    <Delete />
                  </IconButton>,
                ]}
              />
            ))}
            <OptionCard
              key={"missing"}
              label={'Use a different card'}
              onClick={handleSelect(null, field.onChange)}
              selected={field.value === null}
              iconComponent={<Add />}
            >
              {field.value === null && (
                <CardContent>
                  <Stack direction={'column'} gap={2}>
                    <Alert severity="error">Test mode enabled</Alert>
                    <CardConnectTokenForm testModeEnabled={testModeEnabled} />
                  </Stack>
                </CardContent>
              )}
            </OptionCard>
          </Stack>
        )}
      />
    </>
  )
}
