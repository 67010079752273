import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form-mui'
import { useEffect, useMemo } from 'react'
import {
  Button,
  Grid2,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CheckoutTextField from './CheckoutTextField'
import { LoadingButton } from '@mui/lab'
import {
  useGetCountriesQuery,
  useGetStatesQuery,
  useLazyGetStatesQuery,
} from '../../redux/api/advanceApi'
import CheckoutDropdown from './CheckoutDropdown'
import GridSkeleton from '../../common/GridSkeleton'
import _ from 'lodash'
import AddressAutocompleteField from '../../components/AddressAutocompleteField'
import { RadarAddress } from '../../models/Radar'
import { Address } from '../../models/Address'

interface AddressFormProps {
  label: string
  formPrefix?: string
  requirePhone?: boolean
  requireCompany?: boolean
  companyRename?: string
  restrictAddress?: boolean
  restrictPhone?: boolean
  restrictCompany?: boolean
  restrictName?: boolean
  restrictEmail?: boolean
  triggerContact?: boolean
}

export default function AddressForm({
  label,
  formPrefix,
  requirePhone = false,
  requireCompany = false,
  companyRename,
  restrictAddress = false,
  restrictPhone = false,
  restrictCompany = false,
  restrictName = false,
  restrictEmail = false,
  triggerContact,
}: AddressFormProps) {
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const effectivePrefix = useMemo(() => {
    if (formPrefix !== undefined) {
      return `${formPrefix}.`
    } else {
      return ''
    }
  }, [formPrefix])

  const {
    data: countries,
    isLoading: loadingCountries,
    isSuccess: countriesSuccess,
  } = useGetCountriesQuery()

  const { setValue, trigger, getValues } = useFormContext()

  const countryValue: string = useWatch({
    name: `${effectivePrefix}address.country`,
  })

  const { data: states, isFetching: loadingStates } = useGetStatesQuery(
    countryValue,
    { skip: !countryValue },
  )

  useEffect(() => {
    if (countriesSuccess && !getValues(`${effectivePrefix}address.country`)) {
      setValue(`${effectivePrefix}address.country`, 'US')
    }
  }, [countriesSuccess, effectivePrefix, getValues, setValue])

  useEffect(() => {
    if (triggerContact) {
      trigger([`${effectivePrefix}contact`])
    }
  }, [effectivePrefix, trigger, triggerContact])

  const handleAutocomplete = (ra: RadarAddress) => {
    const addr: Address = {
      street1: ra.addressLabel,
      street2: '',
      city: ra.city,
      postalCode: ra.postalCode,
      country: ra.countryCode,
      state: ra.stateCode,
      residential: true,
    }
    setValue(`${effectivePrefix}address`, addr)
  }

  const handleAutocompleteClear = () => {
    setValue(`${effectivePrefix}address`, {
      street1: '',
      street2: '',
      city: '',
      postalCode: '',
      country: '',
      state: '',
      residential: true,
    })
  }

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="h6">{label} Address</Typography>
      {loadingCountries && <GridSkeleton size={6} quantity={12} />}
      {!loadingCountries && (
        <>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.firstName`}
                label="First Name"
                rules={{ required: true }}
                readOnly={restrictName}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.lastName`}
                label="Last Name"
                rules={{ required: true }}
                readOnly={restrictName}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.email`}
                label="Email"
                rules={{ required: true }}
                readOnly={restrictEmail}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.phone`}
                label="Phone"
                rules={{ required: requirePhone }}
                readOnly={restrictPhone}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.company`}
                label={companyRename ?? 'Company'}
                rules={{ required: requireCompany }}
                readOnly={restrictCompany}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}contact.attentionTo`}
                label="Attention To"
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <AddressAutocompleteField
                name={`${effectivePrefix}address.street1`}
                label="Address Line 1"
                onSelect={handleAutocomplete}
                onClear={handleAutocompleteClear}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.street2`}
                label="Address Line 2"
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.city`}
                label="City"
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutTextField
                name={`${effectivePrefix}address.postalCode`}
                label="Postal Code"
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.country`}
                label="Country"
                options={
                  countries?.map((c) => ({ id: c.code, label: c.name })) ?? []
                }
                rules={{ required: true }}
                readOnly={restrictAddress}
              />
            </Grid2>
            <Grid2 size={{ xs: 16, sm: 6 }}>
              <CheckoutDropdown
                name={`${effectivePrefix}address.state`}
                label="State"
                options={
                  states?.map((s) => ({ id: s.code, label: s.name })) ?? []
                }
                rules={{ required: true }}
                loading={loadingStates}
                readOnly={restrictAddress}
              />
            </Grid2>
          </Grid2>
        </>
      )}
    </Stack>
  )
}
