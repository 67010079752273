import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Stack, useMediaQuery, useTheme } from '@mui/system'
import { Alert, Typography } from '@mui/material'
import AddressSelector from '../AddressSelector'
import { FormContainer, useForm, useFormState } from 'react-hook-form-mui'
import { AetherPaymentRequest } from '../../../models/Payment'
import {
  PAYMENT_ICONS,
  PAYMENT_NAMES,
  PaymentMethodType,
} from '../../../models/PaymentMethodType'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectParams,
  selectTaxRate,
  selectSettings,
  selectMethodPaymentData,
  selectPaymentSettings,
  selectUserBudgets,
} from '../../../redux/selectors/checkoutSelectors'
import { getPaymentForm } from './PaymentMethodForms'
import { PaymentMethodSettings } from '../../../models/PaymentMethodSettings'
import OptionCard from '../OptionCard'
import TestModeConfirmDialog from './TestModeConfirmDialog'
import {
  useValidateRequestMutation,
} from '../../../redux/api/paymentApi'
import {
  calculateConvenienceFee,
  getError,
  getItemIds,
  getRestrictedPaymentMessage,
} from '../../../helpers/checkout'
import Decimal from 'decimal.js'
import { LoadingButton } from '@mui/lab'
import parse from 'html-react-parser'
import { RestrictAddressMode } from '../../../models/CheckoutSession'
import { AuthContext } from '../../../common/Auth'
import useFormValue from '../../../common/useFormValue'
import useCurrencyDisplay from '../useCurrencyDisplay'

interface PaymentMethodCardProps {
  settings: PaymentMethodSettings
  request?: Partial<AetherPaymentRequest>
  errorMessage?: string
  index: number | null
  onSelect: () => void
  onSubmit: (data: AetherPaymentRequest) => void
  selected: boolean
}

export default function PaymentMethodCard({
  request,
  settings,
  errorMessage,
  index,
  onSelect,
  onSubmit,
  selected,
}: PaymentMethodCardProps) {
  const { user } = useContext(AuthContext)
  const params = useAppSelector(selectParams)
  const coSettings = useAppSelector(selectSettings)
  const { format } = useCurrencyDisplay()
  const taxRate = useAppSelector((state) => selectTaxRate(state, params))
  const theme = useTheme()
  const xsDisplay = useMediaQuery(theme.breakpoints.only('xs'))

  const paymentSettings = useAppSelector(selectPaymentSettings)

  const budgets = useAppSelector(selectUserBudgets)

  const [
    validateRequest,
    { error: validateError, isLoading: validateLoading },
  ] = useValidateRequestMutation()

  const {
    amount: methodBalanceDue,
    items: methodItems,
    restrictedItemIds,
  } = useAppSelector((state) =>
    selectMethodPaymentData(state, params, index, settings.methodType),
  )

  const [testModeDialog, setTestModeDialog] = useState(false)

  const formContext = useForm<AetherPaymentRequest>({
    defaultValues: useMemo(
      () =>
        request
          ? {
              ...request,
            }
          : {},
      [request],
    ),
  })

  const { reset, handleSubmit } = formContext

  const maximumAmount = useFormValue<AetherPaymentRequest, number | undefined>({
    name: 'maximumAmount',
    formContext,
  })

  const paymentAmount = useMemo(
    () =>
      maximumAmount !== undefined
        ? Decimal.min(maximumAmount, methodBalanceDue)
        : methodBalanceDue,
    [maximumAmount, methodBalanceDue],
  )

  useEffect(() => {
    reset({
      ...(request ?? {}),
    })
  }, [request, reset])

  const { fee: convenienceFee, tax: convenienceFeeTax } = useMemo(() => {
    if (settings.convenienceFeeEnabled) {
      return calculateConvenienceFee(
        paymentAmount,
        settings.convenienceFeePercent ?? 0,
        settings.convenienceFeeTaxable ?? false,
        taxRate ?? 0,
      )
    } else {
      return { fee: new Decimal(0), tax: new Decimal(0) }
    }
  }, [settings, taxRate, paymentAmount])

  const handleFormSubmit = async (data: AetherPaymentRequest) => {
    const amount = paymentAmount
      .plus(convenienceFee)
      .plus(convenienceFeeTax)
      .toNumber()
    const submitData = {
      ...data,
      methodType: settings.methodType,
      itemIds: getItemIds(methodItems, amount),
      amount: amount,
      convenienceFee: convenienceFee.toNumber(),
      convenienceFeeTax: convenienceFeeTax.toNumber(),
      otpEnabled: settings.otpEnabled,
    }
    if (testModeDialog) {
      setTestModeDialog(false)
    } else if (
      submitData.methodType === PaymentMethodType.CARDCONNECT &&
      paymentSettings?.find(
        (s) => s.methodType === PaymentMethodType.CARDCONNECT,
      )?.testModeEnabled
    ) {
      setTestModeDialog(true)
      return
    }

    if (settings.validationRequired) {
      try {
        const validationResponse = await validateRequest(submitData).unwrap()
        submitData.amount = validationResponse.amount
          ? Math.min(validationResponse.amount, submitData.amount)
          : submitData.amount
        submitData.itemIds = getItemIds(methodItems, submitData.amount)
        submitData.validationResponse = validationResponse
        submitData.giftCardId = validationResponse.giftCardId;
        onSubmit(submitData)
      } catch (error) {
        console.error(error)
      }
    } else {
      onSubmit(submitData)
    }
  }

  const methodDisabled = useMemo(() => {
    if (methodBalanceDue.equals(0)) {
      return true
    }
    if (settings.methodType === PaymentMethodType.BUDGET) {
      if (!user || (budgets?.length ?? 0) === 0) {
        return true
      }
    }
  }, [methodBalanceDue, settings, budgets, user])

  const handleCloseTestModeDialog = () => {
    setTestModeDialog(false)
  }

  const handleContinueTestModeDialog = () => {
    handleSubmit(handleFormSubmit)()
  }

  if (methodDisabled) {
    return <></>
  }

  return (
    <FormContainer formContext={formContext}>
      <TestModeConfirmDialog
        open={testModeDialog}
        onClose={handleCloseTestModeDialog}
        onContinue={handleContinueTestModeDialog}
      />
      <OptionCard
        iconComponent={PAYMENT_ICONS[settings.methodType]}
        selected={selected}
        onClick={onSelect}
        label={
          settings.labelRenameEnabled && settings.labelRenameText
            ? settings.labelRenameText
            : PAYMENT_NAMES[settings.methodType]
        }
        warningText={
          (restrictedItemIds?.length ?? 0) > 0 && !selected
            ? getRestrictedPaymentMessage(settings)
            : undefined
        }
        testId={`payment-method-${settings.methodType}-btn`}
      >
        <>
          <AddressSelector
            label={'Billing'}
            requirePhone={coSettings.isBillingPhoneRequired}
            requireCompany={coSettings.isBillingCompanyRequired}
            companyRename={
              coSettings.isBillingCompanyRenameEnabled
                ? coSettings.billingCompanyRenameText
                : undefined
            }
            lockName={coSettings.isBillingNameLocked}
            lockEmail={coSettings.isBillingEmailLocked}
            restrictAddressMode={coSettings.restrictBillingAddressMode}
            restrictAddressId={coSettings.restrictBillingAddressId}
            hideAddress={
              coSettings.restrictBillingAddressMode ===
                RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION &&
              coSettings.isBillingAddressHidden
            }
          >
            <Stack direction={'column'} margin={2} spacing={2}>
              {(restrictedItemIds?.length ?? 0) > 0 && (
                <Alert severity="warning">
                  {getRestrictedPaymentMessage(settings)}
                </Alert>
              )}
              {settings.checkoutHtmlEnabled && settings.checkoutHtml && (
                <Typography variant="body1">
                  {parse(settings.checkoutHtml)}
                </Typography>
              )}
              {getPaymentForm(settings.methodType, {
                settings,
                index,
                methodBalanceDue: methodBalanceDue.toNumber(),
              })}
              {settings.convenienceFeeEnabled && (
                <Typography variant="body1">
                  Convenience Fee:{' '}
                  {format(convenienceFee.plus(convenienceFeeTax).toNumber())}
                </Typography>
              )}
              {!settings.validationRequired && (
                <Typography variant="body1">
                  {`Payment Amount: ${format(
                    paymentAmount.plus(convenienceFee).toNumber(),
                  )}`}
                </Typography>
              )}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              {validateError && (
                <Alert severity="error">{getError(validateError)}</Alert>
              )}
              <Stack direction={'row'} justifyContent={'center'}>
                <LoadingButton
                  data-testid="payment-continue-btn"
                  variant={'contained'}
                  size={'large'}
                  onClick={handleSubmit(handleFormSubmit)}
                  fullWidth={xsDisplay}
                  loading={validateLoading}
                >
                  Continue
                </LoadingButton>
              </Stack>
            </Stack>
          </AddressSelector>
        </>
      </OptionCard>
    </FormContainer>
  )
}
