import React, { useRef } from 'react'
import {
  Badge,
  Box,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material'
import { OrderItem } from '../../models/OrderItem'
import { getItemColorText, getItemSizesText } from '../../helpers/checkout'
import { selectDebug } from '../../redux/selectors/checkoutSelectors'
import { ColorLens, Style } from '@material-ui/icons'
import { useAppSelector } from '../../redux/hooks'
import Decimal from 'decimal.js'
import useCurrencyDisplay from './useCurrencyDisplay'
import { CardGiftcard, Print, Work } from '@mui/icons-material'
import { useHover } from 'usehooks-ts'

const ItemImage = styled('img')({
  maxWidth: '100%',
})

const BubbleBox = styled('div')({
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
})

export function ItemListItem({
  item,
  big = false,
}: {
  item: OrderItem
  big?: boolean
}) {
  const { format } = useCurrencyDisplay()
  const debug = useAppSelector(selectDebug)
  const hoverRef = useRef<HTMLDivElement>(null)
  const isHover = useHover(hoverRef)
  const theme = useTheme()

  const showPersonalizationBubble = item.rows[0]?.personalization?.length > 0
  const showKitBubble = item.aetherData.isKitProduct
  const showGiftCardBubble = item.aetherData.isGiftCard;
  const showDecoBubble =
    !item.aetherData.isPrintProduct &&
    (item.rows[0]?.decoration?.length ?? 0) > 0

  const showPrintBubble =
    item.aetherData.isPrintProduct &&
    (item.rows[0]?.decoration?.length ?? 0) > 0

  return (
    <Stack
      ref={hoverRef}
      direction={'row'}
      p={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={2}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Box position={'relative'}>
          <Badge
            slotProps={{
              badge: {
                style: {
                  transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                  }),
                },
              },
            }}
            variant={isHover ? 'standard' : 'dot'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            invisible={!showPersonalizationBubble && !showKitBubble && !showDecoBubble && !showPrintBubble && !showGiftCardBubble}
            badgeContent={
              <Zoom in={isHover} timeout={theme.transitions.duration.standard}>
                <Stack direction={'row'}>
                  {showPersonalizationBubble && (
                    <Tooltip title={'Personalization Added to Item'}>
                      <BubbleBox>
                        <Style fontSize="inherit" />
                      </BubbleBox>
                    </Tooltip>
                  )}
                  {showKitBubble && (
                    <Tooltip title={'Kit'}>
                      <BubbleBox>
                        <Work fontSize="inherit" />
                      </BubbleBox>
                    </Tooltip>
                  )}
                  {showGiftCardBubble && (
                    <Tooltip title={'Gift Card'}>
                      <BubbleBox>
                        <CardGiftcard fontSize="inherit" />
                      </BubbleBox>
                    </Tooltip>
                  )}
                  {showDecoBubble ? (
                    <Tooltip title={'Artwork Added to Item'}>
                      <BubbleBox>
                        <ColorLens fontSize="inherit" />
                      </BubbleBox>
                    </Tooltip>
                  ) : undefined}
                  {showPrintBubble ? (
                    <Tooltip title={'Print Product'}>
                      <BubbleBox>
                        <Print fontSize="inherit" />
                      </BubbleBox>
                    </Tooltip>
                  ) : undefined}
                </Stack>
              </Zoom>
            }
            color={'primary'}
          >
            <Box width={'2rem'}>
              {(item.rows[0]?.image || item.quoteCustomImage[0]) && (
                <ItemImage
                  src={item.rows[0]?.image || item.quoteCustomImage[0]}
                  alt={item.product.name}
                />
              )}
            </Box>
          </Badge>
        </Box>
        <Stack>
          <Typography variant={big ? 'body1' : 'body2'} gutterBottom>
            {item.product.name}
          </Typography>
          {item.aetherData.showColor && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Color: {getItemColorText(item)}
            </Typography>
          )}
          {item.aetherData.showSize && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Size: {getItemSizesText(item)}
            </Typography>
          )}
          <Typography variant={big ? 'body2' : 'lineCaption'}>
            Qty: {item.quantity}
          </Typography>
          {debug && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Discount: {format(item.aetherData.discount)}{' '}
              {item.aetherData.displayTotalPrice === 0
                ? 0
                : new Decimal(item.aetherData.discount)
                    .times(100)
                    .div(item.aetherData.displayTotalPrice)
                    .toFixed(2)}
              %
            </Typography>
          )}
          {debug && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Tax: {format(item.aetherData.displayTax)}{' '}
              {item.aetherData.displayTotalPrice === 0
                ? 0
                : new Decimal(item.aetherData.displayTax)
                    .times(100)
                    .div(item.aetherData.displayTotalPrice)
                    .toFixed(2)}
              %
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack>
        {item.aetherPriceDisabled ? (
          <Typography variant={big ? 'body1' : 'body2'}> - </Typography>
        ) : (
          <Typography variant={big ? 'body1' : 'body2'}>
            {format(
              item.aetherData.displayTotalPrice,
              item.aetherData.decimalsToShow,
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
