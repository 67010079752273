import { useCallback, useEffect, useMemo, useState } from 'react'
import { RestrictAddressMode } from '../../models/CheckoutSession'
import {
  useLazyGetSavedAddressesQuery,
  useLazyGetShippingLocationQuery,
} from '../../redux/api/coShippingLocationApi'
import { useFormContext, useFormState } from 'react-hook-form-mui'
import { getError, getFirstValue } from '../../helpers/checkout'
import {
  SavedAddress,
  AddressContact,
  SavedAddressSource,
  Address,
} from '../../models/Address'
import { AddressSource } from '../../models/FulfillmentQuote'
import { useAppSelector } from '../../redux/hooks'
import {
  selectContactInfo,
  selectParams,
} from '../../redux/selectors/checkoutSelectors'
import _ from 'lodash'
import { Fulfillment } from '../../models/Order'

interface AddressSelectionProps {
  contactOnly?: boolean
  restrictAddressMode?: RestrictAddressMode
  restrictAddressId?: string
  lockName?: boolean
  lockEmail?: boolean
  requirePhone?: boolean
  requireCompany?: boolean
}

interface AddressSelectionContext {
  loadingAddresses: boolean
  loadingRestrictedAddress: boolean
  addresses: SavedAddress[] | null
  // Is undefined if not yet known (loading)
  // Is false if it is known there are no saved addresses
  hasSavedAddresses: boolean | undefined
  error: string | undefined
  isValid: boolean
  onSelectSavedAddress: (savedAddress: SavedAddress) => void
  onSubmit: () => void
}

export default function useAddressSelection({
  contactOnly,
  restrictAddressMode,
  restrictAddressId,
  lockName,
  lockEmail,
  requirePhone,
  requireCompany,
}: AddressSelectionProps): AddressSelectionContext {
  const params = useAppSelector(selectParams)
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const [
    getSavedAddresses,
    {
      data: addresses,
      isFetching: loadingAddresses,
      isUninitialized: uninitAddr,
      error: errorAddresses,
    },
  ] = useLazyGetSavedAddressesQuery()
  const [
    getShippingLocation,
    { isFetching: loadingRestrictedAddress, error: errorRestrictedAddress },
  ] = useLazyGetShippingLocationQuery()

  const { setValue, reset, trigger, getValues } = useFormContext<Fulfillment>()

  const { dirtyFields } = useFormState()

  const isValidSelection = useCallback(
    (address?: Address, contact?: AddressContact, savedAddressId?: string) => {
      if (
        restrictAddressMode ===
          RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION &&
        savedAddressId !== restrictAddressId
      ) {
        return false
      }

      if (requirePhone && !contact?.phone) {
        return false
      }

      if (
        restrictAddressMode !==
          RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION &&
        requireCompany &&
        !contact?.company
      ) {
        return false
      }

      if (!contactOnly) {
        if (
          !address ||
          !address.street1 ||
          !address.city ||
          !address.state ||
          !address.postalCode ||
          !address.country
        ) {
          return false
        }
      }

      if (
        !contact ||
        !contact.firstName ||
        !contact.lastName ||
        !contact.email
      ) {
        return false
      }
      return true
    },
    [
      restrictAddressMode,
      restrictAddressId,
      requirePhone,
      requireCompany,
      contactOnly,
    ],
  )

  const [isValid, setIsValid] = useState(
    isValidSelection(...getValues([`address`, `contact`, `savedAddressId`])),
  )

  const handleSelectSavedAddress = useCallback(
    async (savedAddress: SavedAddress, submit: boolean) => {
      reset({}, { keepValues: true })
      const contact = getValues(`contact`)
      const updatedContact: AddressContact = {
        firstName: getFirstValue([
          savedAddress?.firstName,
          contact?.firstName,
          contactInfo?.firstName,
        ]),
        lastName: getFirstValue([
          savedAddress?.lastName,
          contact?.lastName,
          contactInfo?.lastName,
        ]),
        email: getFirstValue([
          savedAddress?.email,
          contact?.email,
          contactInfo?.email,
        ]),
        phone: getFirstValue([savedAddress?.phone, contact?.phone]),
        company: getFirstValue([savedAddress?.company, contact?.company]),
      }
      if (lockName) {
        updatedContact.firstName = contactInfo?.firstName
        updatedContact.lastName = contactInfo?.lastName
      }
      if (lockEmail) {
        updatedContact.email = contactInfo?.email
      }
      setValue(`savedAddressId`, savedAddress.id)
      setValue(`address`, savedAddress)
      setValue(`contact`, updatedContact)
      setValue(
        `addressSource`,
        savedAddress.source === SavedAddressSource.SHIPPING_LOCATION
          ? AddressSource.SHIPPING_LOCATION
          : AddressSource.USER_ADDRESS,
      )
      if (submit) {
        await trigger([`address`, `contact`])
        setIsValid(
          isValidSelection(savedAddress, updatedContact, savedAddress.id),
        )
      }
    },
    [
      contactInfo?.email,
      contactInfo?.firstName,
      contactInfo?.lastName,
      getValues,
      isValidSelection,
      lockEmail,
      lockName,
      reset,
      setValue,
      trigger,
    ],
  )

  const onSelectSavedAddress = (savedAddress: SavedAddress) => {
    handleSelectSavedAddress(savedAddress, false)
  }

  useEffect(() => {
    if (contactOnly) return
    const fetchRestrictedAddress = async () => {
      try {
        const address = await getShippingLocation(
          restrictAddressId!,
          true,
        ).unwrap()
        handleSelectSavedAddress(address, true)
      } catch (error) {}
    }

    const fetchSavedAddresses = async () => {
      try {
        const addresses = await getSavedAddresses(
          {
            includeUserAddresses:
              restrictAddressMode !== RestrictAddressMode.SHIPPING_LOCATIONS,
          },
          true,
        ).unwrap()

        // Auto select default address
        if (Object.keys(getValues(`address`) ?? {}).length === 0) {
          const defaultAddress = addresses.find((a) => a.isDefault)
          if (defaultAddress) {
            handleSelectSavedAddress(defaultAddress, true)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (
      restrictAddressId &&
      restrictAddressMode === RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION
    ) {
      fetchRestrictedAddress()
    } else {
      fetchSavedAddresses()
    }
  }, [
    restrictAddressMode,
    restrictAddressId,
    contactOnly,
    getValues,
    getShippingLocation,
    handleSelectSavedAddress,
    getSavedAddresses,
  ])

  const onSubmit = async () => {
    await trigger([`address`, `contact`])
    if (_.get(dirtyFields, `address`.split('.'))) {
      setValue(`savedAddressId`, undefined)
      setValue(`address.locationName`, undefined)
      setValue(`addressSource`, AddressSource.USER_SUPPLIED)
    }
    setIsValid(
      isValidSelection(...getValues([`address`, `contact`, `savedAddressId`])),
    )
  }

  const hasSavedAddresses = useMemo(() => {
    if (
      contactOnly ||
      restrictAddressMode === RestrictAddressMode.SPECIFIC_SHIPPING_LOCATION
    ) {
      return false
    }
    if (uninitAddr || loadingAddresses) {
      return undefined
    }
    return (addresses?.length ?? 0) > 0
  }, [
    contactOnly,
    restrictAddressMode,
    uninitAddr,
    loadingAddresses,
    addresses?.length,
  ])

  return {
    loadingAddresses: loadingAddresses,
    loadingRestrictedAddress: loadingRestrictedAddress,
    hasSavedAddresses,
    addresses: addresses ?? null,
    error: getError(errorAddresses) ?? getError(errorRestrictedAddress),
    isValid,
    onSelectSavedAddress,
    onSubmit,
  }
}
