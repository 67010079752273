import { UserCostCenterResponse } from '../../models/User'
import { aetherApi } from './aetherApi'

const userApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCostCenters: builder.query<UserCostCenterResponse, void>({
      query: () => `api/v1/user/cost-centers/all`,
    }),
  }),
})

export const { useGetUserCostCentersQuery } = userApi
